import SHA256 from 'crypto-js/sha256';

export default class Block {
    constructor(index, data, previousHash = '') {
        this.index = index;
        this.previousHash = previousHash;
        this.timeStamp = this.getTodaysTimeStamp();
        this.data = data;
        this.hash = this.calculateHash();
    }

    calculateHash() {
        return SHA256(this.index + this.previousHash + this.timeStamp + JSON.stringify(this.data)).toString();
    }

    getTodaysTimeStamp() {
        const today = new Date();
        const todayDateString = `${today.getMonth().toString().padStart(2, '0')}/${today.getDate().toString().padStart(2, '0')}/${today.getFullYear()}`;
        const todayTimeString = `${today.getHours().toString().padStart(2, '0')}:${today.getMinutes().toString().padStart(2, '0')}:${today.getSeconds().toString().padStart(2, '0')}`;
        const todayString = `${todayDateString} ${todayTimeString}`;

        return todayString;
    }

    isValid() {
        return this.hash === this.calculateHash();
    }
}