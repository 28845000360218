import React, { useState } from 'react';
import '../styles/BlockView.css';

const BlockView = ({ block, onAddBlock, onSaveBlock, latestHash }) => {
    const [addInputValue, setAddInputValue] = useState('');
    const [editInputValue, setEditInputValue] = useState('');
    const [isEditing, setIsEditing] = useState(false);

    const handleEditClick = () => {
        setEditInputValue(block.data);
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        onSaveBlock({
            ...block,
            data: parseInt(editInputValue.trim()) || 0
        });
        setIsEditing(false);
    };

    const handleAddBlockClick = () => {
        onAddBlock(parseInt(addInputValue.trim()) || 0);
        setAddInputValue('');
    };

    const handleAddBlockKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleAddBlockClick();
        }
    };

    return (
        <div className="block-container mt-2">
            <div className={`block ${block.isValid() ? '' : 'invalid'}`}>
                <div className="nowrap">
                    <span className="mr-2">#{block.index}</span>
                    {isEditing ? (
                        <input className="bold mr-2" value={editInputValue} onChange={(e) => setEditInputValue(e.target.value)} />
                    ) : (
                            <span className="bold mr-2">[{block.data === 'genesis block' ? block.data : '$' + block.data}]</span>
                        )}
                    <span className="mr-2">{block.timeStamp}</span>
                </div>
                {isEditing ? (
                    <div className="mt-2 text-sm">
                        <div className="help hover-gray nowrap" title="Hash of the previous block">&lArr; <span className="text-xxs">{block.previousHash}</span></div>
                        <div className="help hover-gray nowrap" title="Hash of this block">&hArr; <span className="text-xxs">{block.hash}</span></div>
                        <div className="mt-1">
                            <button className="mr-1" onClick={handleSaveClick}>save</button>
                            <button onClick={() => setIsEditing(false)}>cancel</button>
                        </div>
                    </div>
                ) : (
                        <div className="mt-1">
                            {block.hash === latestHash &&
                                <>
                                    <input className="mr-1"
                                        type="text"
                                        placeholder="bun amount..."
                                        value={addInputValue}
                                        onChange={(e) => setAddInputValue(e.target.value)}
                                        onKeyPress={handleAddBlockKeyPress}
                                    />
                                    <button className="mr-1" onClick={handleAddBlockClick}>add</button>
                                </>
                            }
                            <button onClick={handleEditClick}>edit</button>
                        </div>
                    )}
            </div>
        </div>
    )
}

export default BlockView;