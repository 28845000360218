import React from 'react';
import BlockView from './BlockView';

const BlockchainView = ({ blockchain, isValid, onAddBlock, onSaveBlock }) => {
  return (
    <div className='blockchain-container mt-2'>
      {!isValid &&
        <span className='bold text-invalid'>this blockchain has been violated and is no longer valid</span>
      }
      {blockchain.chain.map((block) => (
        <BlockView key={block.index}
          block={block}
          onAddBlock={onAddBlock}
          onSaveBlock={onSaveBlock}
          latestHash={blockchain.getLatestBlock().hash}
        />
      ))}
    </div>
  )
}

export default BlockchainView;